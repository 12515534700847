import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { ReactElement, ReactNode } from 'react';
import { Fragment } from 'react';

export const client = new QueryClient({
	queryCache: new QueryCache({
		onError: (error): void => {
			console.log(error);
		},
	}),
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 1000 * 60 * 5,
			cacheTime: 1000 * 60 * 5,
		},
		mutations: {
			onError: (error): void => {
				console.error(error);
			},
		},
	},
});

export const QueryProvider = ({
	children, //
}: {
	children: ReactNode;
}): ReactElement => {
	return (
		<QueryClientProvider client={client}>
			<Fragment>{children}</Fragment>
		</QueryClientProvider>
	);
};
